<template>
  <div
    id="PMReportDownload"
    style="width: 60%; margin: auto !important"
    class="items-center justify-center inset-0 ..."
  >
    <table style="background: white" class="text-center w-full">
      <tr class="HeaderTableColor h-25" style="height: 70px">
        <th class="h-25 text-center">
          {{ $t("PendingMedicalDocuments") }}
        </th>
        <th class="h-25 text-center">
          {{ $t("AppointmentDate") }}
        </th>
        <th class="h-25 text-center">
          {{ $t("Patient") }}
        </th>
        <th class="h-25 text-center">
          <div class="MyPopClose">
            <feather-icon
              class="m-2"
              icon="XIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              @click.stop="$emit('closePop')"
            />
          </div>
        </th>
      </tr>
      <tr :data="tr" :key="indextr" v-for="(tr, indextr) in Model">
        <td>
          <p class="product-name font-medium truncate">
            {{ tr.ReportTypeName }}
          </p>
        </td>
        <td>
          <p class="product-name font-medium truncate" type="date" v-if="tr.Date">
            {{ tr.Date.split("T")[0] }}
          </p>
        </td>
        <td>

<imageLazy
                      :imageStyle="{ 'border-radius': '20px' , 'width':'40px' , 'height':'40px' }"
                      :imageClass="'rounded-full'"
                      :src="baseURL + tr.PatientImage"
                      placeHolderType="patient"
                    />
          <p
            class="product-name font-medium truncate"
            style="font: normal normal normal 15px/20px Futura PT"
          >
            {{ tr.PatientName }}
          </p>
        </td>
        <td style="background: primary">
          <div class="m-3" v-if="tr.AttachPath != null || tr.AttachPath != undefined">
            <a
              :href="baseURL + tr.AttachPath"
              download
              target="_blank"
              @click="changeDocStatus(tr)"
            >
              <vs-avatar
                color="primary"
                icon-pack="feather"
                icon="icon-download-cloud"
                style="background: primary"

            /></a>
            <p
              style="
                color: #ffffff;
                font-size: 12px;
                opacity: 1;
                line-height: 30px;
              "
            >
              {{ $t("Download") }}
            </p>
          </div>
        </td>
      </tr>

      <tr v-if="Model.length == 0">
        <td></td>

        <td>
          <h3 class="w-full sm:w-auto text-center text-primary mt-5 mb-5">
            {{ $t("NoDataToshow") }}
          </h3>
        </td>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script>
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components:{
    imageLazy
  },

  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  props: {
    patientDoc: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        debugger;
        return this.$refs.table.currentx;
      }
      return 0;
    },
    doctorMedical() {
      return this.$store.state.DoctorList.patientReservation;
    },
  },
  methods: {
    changeDocStatus(Doc) {
      debugger
      Doc;
      debugger
      var model = {};
      model.doctorID = Doc.DoctorID;
      model.patientID = Doc.PatientID;
      model.PatientReservationSessionID = Doc.PatientReservationID;

      model.ID = Doc.ID;
      model.DocumentTypeID = Doc.DocumentTypeID;

      debugger;
      this.$store
        .dispatch("DoctorList/ChangeDocStatus", model)
        .then((res) => {
          if (res.status == 200) {

            debugger;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    searchData() {
      debugger;
      if (this.patientDoc.PatientID) {
        if (this.patientDoc.DoctorID) {
          debugger;
          var model = {};
          model.doctorID = this.patientDoc.DoctorID;
          model.patientID = this.patientDoc.PatientID;
          model.PatientReservationSessionID = this.$route.params.PatientReservationID;
          debugger;
          this.$store
            .dispatch("DoctorList/PatientMedicalReportRequestDownloadDoc", model)
            .then((res) => {
              if (res.status == 200) {
                debugger;
                this.Model = res.data.Data;
                if (this.Model == null || this.Model.length == 0) {
                  this.$vs.notify({
                    title: this.$t("NoData"),
                    text: this.$t("NoDataToshow"),
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "warning",
                  });
                }
                debugger;
              }
            })
            .catch(() => {
              this.$vs.loading.close();
            });
        }
      }
    },
  },
  created() {
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    debugger;
    this.searchData();
  },
};
</script>
<style>
#PMReportDownload table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  background: white;
}
#PMReportDownload th,
#PMReportDownload td {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
  border-collapse: collapse;
  text-align: center;
  font: normal normal normal 20px/25px Futura PT;
}
#PMReportDownload .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#PMReportDownload .login-tabs-container {
  min-height: 505px;
}
#PMReportDownload .con-tab {
  padding-bottom: 14px;
}

#PMReportDownload .con-slot-tabs {
  margin-top: 1rem;
}
#PMReportDownload .background {
  color: rgb(255, 9, 9);
}
#PMReportDownload .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#PMReportDownload .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
[dir] #PMReportDownload th,
[dir] #PMReportDownload td {
  border: 1px solid #cfcfcf !important;
}
</style>
